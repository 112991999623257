<template>
  <div class="TicketInfoVuelo">
    <div class="TicketInfoVuelo__infoGeneral">
      <h2>
        {{ documentoCompra.details.ida.data.origen }} -
        {{ documentoCompra.details.ida.data.destino }}
      </h2>
      <h4>
        {{
          documentoCompra.details.info.tipoDeViaje === "Vuelos"
            ? documentoCompra.details.info.tipoDeVuelo
            : "Experiencia"
        }}
        {{
          !experienciaTipoReserva
            ? documentoCompra.details.info.pasajeros > 1
              ? `${documentoCompra.details.info.pasajeros} pasajeros`
              : `${documentoCompra.details.info.pasajeros} pasajero`
            : ""
        }}
      </h4>
    </div>
    <div class="TicketInfoVuelo__infoEspecifica">
      <v-row no-gutters class="pb-4">
        <v-col cols="2" v-if="!experienciaTipoReserva">
          <h3>
            {{
              documentoCompra.details.info.tipoDeVuelo === "Ida y vuelta"
                ? "IDA"
                : "Fecha de vuelo"
            }}
          </h3>
          <h4>{{ documentoCompra.fecha }}</h4>
        </v-col>
        <v-col cols="2" v-if="!experienciaTipoReserva">
          <h3>Experiencia</h3>
          <h4>
            <a
              :href="
                `https://hummingbird.cl/viajes/Experiencias/${documentoCompra.details.ida.id}`
              "
            >
              {{ documentoCompra.details.ida.data.destino }}
            </a>
          </h4>
        </v-col>
        <v-col cols="12" sm="2">
          <h3>Código de vuelo</h3>
          <h4>{{ documentoCompra.details.ida.id }}</h4>
        </v-col>
        <v-col cols="12" sm="4">
          <h3>Email del comprador:</h3>
          <h4>{{ documentoCompra.userData.email }}</h4>
        </v-col>
        <v-col cols="12" sm="4" v-if="documentoCompra.details.ida.experienciaAMedidaDetails">
          <h3>Detalles:</h3>
          <h4>{{ documentoCompra.details.ida.experienciaAMedidaDetails.detalles }}</h4>
        </v-col>
      </v-row>
      <hr v-if="documentoCompra.details.vuelta" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TicketCompra",
  props: {
    documentoCompra: {
      type: Object,
      required: true,
    },
  },
  computed: {
    experienciaTipoReserva() {
      return !!this.documentoCompra.details.ida.data.reserva;
    },
  },
};
</script>

<style lang="scss" scoped>
.TicketInfoVuelo {
  // width: 310px;
  &__infoGeneral,
  &__infoEspecifica {
    background: $main-white;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    padding: 30px;
  }
  &__infoGeneral {
    border-bottom: 1px dashed $main-black;
  }
}
</style>
