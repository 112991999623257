<template>
  <v-container v-if="error" class="text-center">
    Error: {{ error }}
  </v-container>
  <v-container v-else-if="documentoCompra === undefined" class="text-center">
    No hay documentos de compra que coincidan con el id ingresado
  </v-container>
  <v-container v-else class="ma-auto">
    <div v-if="documentoCompra">
      <TIcketCompraRealizada :documentoCompra="documentoCompra" class="pb-4">
        <v-divider class="pb-3" />
        <v-row no-gutters>
          <v-col cols="12" md="2">
            <h3 class="itemTitle">Fecha de compra</h3>
            <p class="itemContent">
              {{
                new Date(documentoCompra.date).toLocaleDateString("es-ES", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </p>
          </v-col>
          <v-col cols="12" md="2">
            <h3 class="itemTitle">Monto total</h3>
            <p class="itemContent">
              ${{ precioEnMiles(documentoCompra.details.total) }}
            </p>
          </v-col>
          <v-col cols="12" md="2">
            <h3 class="itemTitle">Código de venta</h3>
            <p class="itemContent">
              {{ idCompra }}
            </p>
          </v-col>
          <v-col cols="12" md="2">
            <h3 class="itemTitle">Comprado por:</h3>
            <p class="itemContent">
              {{ documentoCompra.userData.displayName }}
            </p>
          </v-col>
        </v-row>

        <v-col cols="12">
          <h3 class="itemTitle mb-2">Lista pasajeros:</h3>

          <table border="solid" class="text-left">
            <thead class="font-weight-bold">
              <th class="px-3" style="width: 220px">Nombre</th>
              <th class="px-3" style="width: 120px">Rut</th>
              <th class="px-3" style="width: 120px">Teléfono</th>
              <th class="px-3">Peso</th>
            </thead>
            <tbody>
              <tr
                v-for="(pasajero, i) of documentoCompra.details.listaPasajeros"
                :key="i"
              >
                <td class="px-3">{{ pasajero.nombre }}</td>
                <td class="px-3">{{ pasajero.rut }}</td>
                <td class="px-3">{{ pasajero.telefono }}</td>
                <td class="px-3">{{ pasajero.peso }} kg</td>
              </tr>
              <tr>
                <td class="px-3"></td>
                <td class="px-3"></td>
                <td class="px-3"></td>
                <td class="font-weight-bold px-3">
                  {{
                    documentoCompra.details.listaPasajeros
                      .map((p) => +p.peso)
                      .reduce((a, b) => a + b, 0)
                  }}
                  kg
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col cols="12" v-if="documentoCompra.details.datosDeFacturacion">
          <h3 class="itemTitle mb-2">Datos de facturación:</h3>

          <table border="solid" class="text-left">
            <thead class="font-weight-bold">
              <th class="px-3" style="width: 220px">Tipo</th>
              <th class="px-3" style="width: 120px">RUT</th>
              <th class="px-3" style="width: 320px">Razón Social</th>
              <th class="px-3" style="width: 220px">Dirección</th>
              <th class="px-3" style="width: 120px">Giro</th>
            </thead>
            <tbody>
              <tr>
                <td class="px-3">
                  {{ documentoCompra.details.datosDeFacturacion.tipoDeUsuario }}
                </td>
                <td class="px-3">
                  {{ documentoCompra.details.datosDeFacturacion.rut }}
                </td>
                <td class="px-3">
                  {{ documentoCompra.details.datosDeFacturacion.razonSocial }}
                </td>
                <td class="px-3">
                  {{ documentoCompra.details.datosDeFacturacion.direccion }}
                </td>
                <td class="px-3">
                  {{ documentoCompra.details.datosDeFacturacion.giro }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </TIcketCompraRealizada>
    </div>
  </v-container>
</template>

<script>
import TIcketCompraRealizada from "@/components/Cards/TIcketCompraRealizada";
import firebase from "firebase";

export default {
  props: {
    idCompra: {
      type: String,
      required: true,
    },
  },

  components: {
    TIcketCompraRealizada,
  },
  data() {
    return {
      documentoCompra: null,
      error: null,
    };
  },
  computed: {
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  methods: {
    async getData() {
      this.idCompra = this.idCompra.trim();
      try {
        await firebase
          .firestore()
          .collection("ventas")
          .doc(this.idCompra)
          .onSnapshot((doc) => {
            console.log((this.documentoCompra = doc.data()));
          });
      } catch (error) {
        this.error = error.message;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.itemTitle {
  font-size: 15px;
}
.itemContent {
  font-size: 12px;
}
</style>
